import Alpine from 'alpinejs';
import mask from '@alpinejs/mask'
import collapse from '@alpinejs/collapse'
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Swiper from 'swiper';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';

// Modules
//import { exampleModule } from './modules/example';

// Exports
//exampleModule();

// Swiper
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

window.Swiper = Swiper;
Swiper.use([Autoplay, EffectFade, Pagination, Navigation]);

document.documentElement.classList.remove('no-js');

// GSAP;
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;
window.ScrollToPlugin = ScrollToPlugin;

// Global Exports
window.Alpine = Alpine;
Alpine.plugin(mask);
Alpine.plugin(collapse);
Alpine.start();

